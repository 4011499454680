import {FaInstagram} from "react-icons/all";

export const DETAILS = {
    name: " عایق الاستومری مهرگان",
    shortName: "مهرگان",
    footerName: "مهرگان عایق",
    description: "",
    shortAbout: "امروزه اهميت صرفه جويى در مصرف انرژى و هزينه هاى سنگين انرژى بر كسى پوشيده نيست و تمام تمركز اصلى سازندگان در هر كشورى بهينه سازى مصرف انرژى و محافظت و احياى محيط زيست است. به همين دليل صنعت عايق به عنوان راه حلى مطمئن و مقرون به صرفه مورد توجه كشورهاى پيشرفته قرار كرفته است. اما متاسفانه صنعت عايقكارى در ايران جايگاه مناسب نداشته و اهميت استفاده از عايق و كاربرد هاى آن ناديده گرفته مى شود. لذا مهرگان عایق با بهره گيرى از دانش روز كشورهاى اروپايى ضمن شناخت از نياز بازار اقدام به عرضه محصولى با كلاس جهانى وكيفيتى عالى نموده است.",
    veryShortAbout: "",
    link: "https://mehrganayegh.ir",
    mobile: ["09122109768", "09102236181"],
    phone: ["02133995058", "02133995059"],
    email: [],
    postalCode: [],
    address: "تهران، میدان امام خمینی، خیابان ناصرخسرو، کوچه پست، قبل از بانک ایران زمین، کوچه ناهور، پلاک ۲۵",
    supportDescription: "",
    workingHours: ["شنبه تا چهارشنبه 9 تا 18", "پنجشنبه 9 تا 14", "جمعه تعطیل"],
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6481.397743570618!2d51.42289!3d35.684416!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQxJzAzLjkiTiA1McKwMjUnMjIuNCJF!5e0!3m2!1sen!2s!4v1706702992970!5m2!1sen!2s",
    socialMedia: [
        /*{
            title: 'واتس اپ',
            value: '09380637888',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09380637888&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },*/
        /*{
            title: 'تلگرام',
            value: '09357195494',
            name: 'telegram',
            link: 'https://t.me/+989357195494',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },*/
        {
            title: 'اینستاگرام',
            value: 'ayegh_komijani',
            name: 'instagram',
            link: "https://instagram.com/ayegh_komijani",
            icon: <FaInstagram/>,
            isPrimary: true
        },
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    enamad: {
        link: "https://trustseal.enamad.ir/?id=462219&Code=f1B4zQSUfbcXGTapVYwBvHftHg4FcBkB",
        src: "https://trustseal.enamad.ir/logo.aspx?id=462219&Code=f1B4zQSUfbcXGTapVYwBvHftHg4FcBkB",
        code: "f1B4zQSUfbcXGTapVYwBvHftHg4FcBkB"
    },
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
